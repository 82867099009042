<template>
  <div class="prefilled" v-click-outside="closePrefilledModal">
    <button
      type="button"
      class="prefilled__btn"
      :class="{ 'prefilled__btn--active': isPrefilledModalOpen }"
      @click.prevent="togglePrefilledModal"
    >
      <AppIcon componentName="PlusSimpleIcon" />
      SUGGESTED DESCRIPTION
    </button>

    <transition name="fade">
      <div class="popup_container mobile" v-if="isMobileDisplay">
        <div
          v-show="isMobileDisplay ? true : isPrefilledModalOpen"
          class="prefilled-modal"
          :class="{
            'prefilled-modal--open': isPrefilledModalOpen && isMobileDisplay,
          }"
          ref="prefilledModal"
        >
          <div class="prefilled-close">
            <button
              type="button"
              class="prefilled-close__btn"
              @click.stop.prevent="closePrefilledModal"
            >
              &#x2715;
            </button>
          </div>
          <div class="prefilled-search">
            <input
              type="text"
              class="prefilled-search__field"
              :placeholder="preFillPlaceHolder"
              v-model="prefilledQueryString"
              @input="searchPrefilled"
              @keydown.enter.stop.prevent="searchPrefilled"
            />

            <button
              type="button"
              class="prefilled-search__btn"
              @click.stop.prevent="searchPrefilled"
            >
              <AppIcon componentName="SearchIcon" />
            </button>
          </div>
          <div class="prefilled-results-wrap" ref="wrap">
            <transition name="fade">
              <div v-if="prefilledLoading" class="prefilled-results-loading">
                <AppEmbedLoader size="40" color="#4874e6" />
              </div>
            </transition>

            <div class="prefilled-results">
              <div
                v-for="prefilledItem of prefilled.prefilledData"
                :key="prefilledItem.id"
                class="prefilled-results-item"
                @click.prevent="
                  addPreFilled(prefilledItem.content, prefilledItem.id)
                "
                ref="prefilledItems"
              >
                <div :class="`plus-icon d-block icon-${prefilledItem.id}`">
                  <AppIcon
                    componentName="PlusSimpleIcon"
                    class="prefilled-results-item__icon"
                  />
                </div>
                <div :class="`minus-icon d-none icon-${prefilledItem.id}`">
                  <AppIcon
                    componentName="MinusSimpleIcon"
                    class="prefilled-results-item__icon"
                  />
                </div>
                <p
                  class="prefilled-results-item__text"
                  v-html="prefilledItem.content"
                />
              </div>
            </div>

            <div v-if="!prefilled.prefilledData.length" class="no-prefilled">
              <p>No pre-filled data found!</p>
            </div>
          </div>
        </div>
      </div>
      <div class="popup_container desktop" v-else>
        <div
          v-if="isMobileDisplay ? true : isPrefilledModalOpen"
          class="prefilled-modal"
          :class="{
            'prefilled-modal--open': isPrefilledModalOpen && isMobileDisplay,
          }"
        >
          <div class="resultboard">
            <div class="board_title">
              <span>Responsibilities and Achievements</span>
            </div>
            <div class="sub_title" v-if="!isPrefillEditable">
              <i
                >Texts can be edited on the next page upon saving the templated
                descriptions</i
              >
            </div>
            <div class="editor-wrap" v-if="isPrefillEditable">
              <quill-editor
                v-model="contentEditor"
                :options="editorOptions"
                @change="changeContent"
              />
            </div>
            <div
              v-else
              class="selecteditems"
              v-html="contentEditorPreview"
              ref="selecteditems"
            ></div>
            <div class="btn_fields">
              <button
                class="btn cancel_btn btn--secondary"
                @click.prevent="cancelChanges"
              >
                <span class="btn__text">Cancel</span>
              </button>
              <button
                class="btn save_btn btn--primary"
                @click.prevent="togglePrefilledModal"
              >
                <span class="btn__text">Save</span>
              </button>
            </div>
          </div>
          <div class="selectionboard">
            <div class="prefilled-modal-header d-lg-none">
              <h3 class="prefilled-modal-header__title">Select pre filled</h3>

              <button
                class="prefilled-modal-header__btn"
                @click.prevent.stop="closePrefilledModal"
              >
                <AppIcon componentName="CloseIcon" />
              </button>
            </div>
            <div class="prefilled-search">
              <input
                type="text"
                class="prefilled-search__field"
                :placeholder="preFillPlaceHolder"
                v-model="prefilledQueryString"
                @input="searchPrefilled"
                @keydown.enter.stop.prevent="searchPrefilled"
              />

              <button
                type="button"
                class="prefilled-search__btn"
                @click.stop.prevent="searchPrefilled"
              >
                <AppIcon componentName="SearchIcon" />
              </button>
            </div>

            <div class="prefilled-results-wrap">
              <transition name="fade">
                <div v-if="prefilledLoading" class="prefilled-results-loading">
                  <AppEmbedLoader size="40" color="#4874e6" />
                </div>
              </transition>

              <div class="prefilled-results">
                <div
                  v-for="prefilledItem of prefilled.prefilledData"
                  :key="prefilledItem.id"
                  class="prefilled-results-item"
                  @click.prevent="
                    addPreFilled(prefilledItem.content, prefilledItem.id)
                  "
                  ref="prefilledItems"
                >
                  <div :class="`plus-icon d-block icon-${prefilledItem.id}`">
                    <AppIcon
                      componentName="PlusSimpleIcon"
                      class="prefilled-results-item__icon"
                    />
                  </div>
                  <div :class="`minus-icon d-none icon-${prefilledItem.id}`">
                    <AppIcon
                      componentName="MinusSimpleIcon"
                      class="prefilled-results-item__icon"
                    />
                  </div>
                  <p
                    class="prefilled-results-item__text"
                    v-html="prefilledItem.content"
                  />
                </div>
              </div>

              <div v-if="!prefilled.prefilledData.length" class="no-prefilled">
                <p>No pre-filled data found!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import $ from "jquery";
import { debounce } from "lodash/function";
import AppEmbedLoader from "@/components/ui/AppEmbedLoader";
import iNoBounce from "@/inobounce";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "PreFilledModal",
  components: { AppEmbedLoader, quillEditor },

  inject: ["prefilled"],

  computed: {
    searchPrefilledDebounced() {
      return debounce(function () {
        this.getPrefilled();
      }, 500);
    },
  },

  props: {
    preFillDefaultValue: {
      type: String,
    },
    preFillPlaceHolder: {
      type: String,
      default: "Search",
    },
    contentEditor: {
      type: String,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    isPrefillEditable: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * disable loader when prefilled array changed
   */
  watch: {
    prefilled: {
      deep: true,
      handler() {
        this.prefilledLoading = false;
        this.checkSelectedItems();
      },
    },
    contentEditor: {
      deep: true,
      handler() {
        this.contentSelectedItems();
      },
    },
  },

  data() {
    return {
      prefilledQueryString: "",
      prefilledLoading: false,
      isPrefilledModalOpen: false,
      isMobileDisplay: window.innerWidth < 992,
      isFirstLoading: true,
      contentEditorBackup: "",
      contentEditorPreview: "",
      startingY: null,
      scrollCurrentY: 0,
      scrollTop: 0,
      scrolledToTop: false,
      isIos: navigator.userAgent.match(/iPad|iPhone|iPod/i) != null,
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
    };
  },

  created() {
    let vm = this;
    window.addEventListener("resize", function () {
      vm.isMobileDisplay = window.innerWidth < 992;
    });
  },

  methods: {
    changeContent() {
      this.checkSelectedItems();
    },
    searchPrefilled(input) {
      this.prefilledQueryString = input.target.value;
      this.prefilledLoading = true;

      this.searchPrefilledDebounced();
    },
    changeIcon(id) {
      this.$refs.prefilledItems.forEach((items) => {
        items.childNodes.forEach((item) => {
          if (item.classList.contains(`icon-${id}`)) {
            if (item.classList.contains("d-none")) {
              item.classList.remove("d-none");
              item.classList.add("d-block");
            } else {
              item.classList.remove("d-block");
              item.classList.add("d-none");
            }
          }
        });
      });
    },
    addPreFilled(selectedPrefilled, id) {
      this.changeIcon(id);
      this.$emit("updatePrefilled", this.contentEditor);
      this.$emit("select", selectedPrefilled);
    },

    cancelChanges() {
      this.$emit("cancel", this.contentEditorBackup);
      this.closePrefilledModal();
    },

    openPrefilledModal() {
      document
        .querySelector(".ql-container.ql-snow .ql-editor")
        .setAttribute("contenteditable", false);
      this.isPrefilledModalOpen = true;
      this.$emit("openPrefilled");
    },

    closePrefilledModal() {
      if (this.isMobileDisplay && this.isPrefilledModalOpen) {
        this.$refs.prefilledModal.removeAttribute("style");
      }

      if (this.isPrefilledModalOpen) {
        document
          .querySelector(".ql-container.ql-snow .ql-editor")
          .setAttribute("contenteditable", true);
        iNoBounce.disable();
      }
      $(".creation-sidebar").removeClass("inactive");

      //$(".creation-sidebar").css("z-index", 999);
      this.isPrefilledModalOpen = false;
      this.$emit("closePrefilled");
    },

    togglePrefill() {
      $(".creation-sidebar").css("z-index", 0);
      setTimeout(() => {
        this.togglePrefilledModal();
      }, 200);
    },

    togglePrefilledModal() {
      let vm = this;
      this.contentEditorBackup = this.contentEditor;

      $(".creation-sidebar").addClass("inactive");

      this.isPrefilledModalOpen = !this.isPrefilledModalOpen;
      if (vm.isPrefilledModalOpen) {
        this.$emit("openPrefilled");
        iNoBounce.enable();
      } else {
        this.$emit("closePrefilled");
        this.$emit("updatePrefilled", this.contentEditor);
      }

      if (vm.isPrefilledModalOpen && this.label != "Summary") {
        if (this.isIos && this.isEditing) {
          $(".prefilled-close").addClass("prefilled-close-is-ios");
        }
      } else {
        $(".prefilled-close").removeClass("prefilled-close-is-ios");
      }

      this.prefilledQueryString = this.preFillDefaultValue;
      if (this.prefilledQueryString != "") {
        this.prefilledLoading = true;
        this.$emit("searchPrefilled", this.prefilledQueryString);
      } else {
        if (this.isFirstLoading && this.isPrefilledModalOpen) {
          this.prefilledLoading = true;
          this.$emit("searchPrefilled");
          this.isFirstLoading = false;
        }
      }
      this.contentSelectedItems();

      this.checkSelectedItems();
      if (this.isPrefilledModalOpen) {
        document
          .querySelector(".ql-container.ql-snow .ql-editor")
          .setAttribute("contenteditable", false);
      } else {
        document
          .querySelector(".ql-container.ql-snow .ql-editor")
          .setAttribute("contenteditable", true);
      }
    },

    getPrefilled() {
      this.$emit("searchPrefilled", this.prefilledQueryString);
    },
    checkSelectedItems() {
      this.$nextTick(() => {
        if (!this.$refs.prefilledItems) return;
        this.$refs.prefilledItems.forEach((items) => {
          const plusIcon = items.querySelector(".plus-icon");
          const minusIcon = items.querySelector(".minus-icon");
          let prefilledText = items.querySelector(
            ".prefilled-results-item__text"
          );
          const contentEditor = this.contentEditor.replace(/<a[^>]*>/g, "<a>");
          let prefilledTextWithoutAttr = prefilledText.innerHTML.replace(
            /<\/span>/g,
            "</a>"
          );
          let span = prefilledText.innerHTML.match(/<span[^>]*>/g);
          if (span && span.length) {
            span.forEach(() => {
              prefilledTextWithoutAttr = prefilledTextWithoutAttr.replace(
                /<span[^>]*>/,
                "<a>"
              );
            });
          }
          if (contentEditor.includes(prefilledTextWithoutAttr)) {
            if (plusIcon.classList.contains("d-block")) {
              plusIcon.classList.remove("d-block");
              plusIcon.classList.add("d-none");
            }
            if (minusIcon.classList.contains("d-none")) {
              minusIcon.classList.remove("d-none");
              minusIcon.classList.add("d-block");
            }
          } else {
            if (plusIcon.classList.contains("d-none")) {
              plusIcon.classList.remove("d-none");
              plusIcon.classList.add("d-block");
            }
            if (minusIcon.classList.contains("d-block")) {
              minusIcon.classList.remove("d-block");
              minusIcon.classList.add("d-none");
            }
          }
        });
      });
    },
    contentSelectedItems() {
      this.contentEditorPreview = this.contentEditor.replace(/<a[^>]*>/g, "");
      this.contentEditorPreview = this.contentEditorPreview.replace(
        /<\/a>/g,
        ""
      );
    },
    onScroll() {
      this.scrollTop = this.$refs.wrap.scrollTop;
    },
    isTouchedOnTop(evt) {
      const classes = [
        "prefilled-modal",
        "prefilled-modal--open",
        "prefilled-modal prefilled-modal--open",
        "prefilled-close",
        "prefilled-close__btn",
        "prefilled-search",
        "prefilled-search__field",
        "prefilled-search__btn",
      ];
      if (
        evt.target.tagName == "svg" &&
        classes.includes(evt.target.parentNode.className)
      ) {
        return true;
      } else if (
        evt.target.tagName == "path" &&
        classes.includes(evt.target.parentNode.parentNode.className)
      ) {
        return true;
      } else if (classes.includes(evt.target.className)) {
        return true;
      } else {
        return false;
      }
    },
    handleTouchStartPrefillResult() {
      iNoBounce.disable();
    },
    handleTouchEndPrefillResult() {
      iNoBounce.enable();
    },

    handleTouchStart(evt) {
      this.startingY = evt.touches[0].clientY;
    },

    handleTouchMove(evt) {
      if (
        this.scrollTop == 0 ||
        this.scrolledToTop ||
        this.isTouchedOnTop(evt)
      ) {
        this.scrolledToTop = true;
        var change = evt.touches[0].clientY - this.startingY;
        if (change < 0) {
          return;
        }
        this.$refs.prefilledModal.style["-webkit-transform"] =
          "translateY(" + change + "px)";
        this.$refs.prefilledModal.style.transform =
          "translateY(" + change + "px)";
      }
    },
    handleTouchEnd(evt) {
      if (
        this.scrollTop == 0 ||
        this.scrolledToTop ||
        this.isTouchedOnTop(evt)
      ) {
        this.scrolledToTop = false;
        var change = evt.changedTouches[0].clientY - this.startingY;
        if (change < 200) {
          this.$refs.prefilledModal.style["-webkit-transform"] =
            "translateY(0)";
          this.$refs.prefilledModal.style.transform = "translateY(0)";
        } else {
          this.$refs.prefilledModal.removeAttribute("style");
          this.closePrefilledModal();
        }
      }
    },
  },
  mounted() {
    iNoBounce.disable();

    if (this.isMobileDisplay) {
      document
        .querySelector(".prefilled-results-wrap")
        .addEventListener("scroll", this.onScroll);
      document
        .querySelector(".prefilled-modal")
        .addEventListener("touchstart", this.handleTouchStart);
      document
        .querySelector(".prefilled-modal")
        .addEventListener("touchmove", this.handleTouchMove);
      document
        .querySelector(".prefilled-modal")
        .addEventListener("touchend", this.handleTouchEnd);

      document
        .querySelector(".prefilled-results")
        .addEventListener("touchstart", this.handleTouchStartPrefillResult);
      document
        .querySelector(".prefilled-results")
        .addEventListener("touchend", this.handleTouchEndPrefillResult);
    }
    if (navigator.userAgent.match(/iPhone|iPod|iPad/i)) {
      this.isIos = true;
    }
  },
  beforeDestroy() {
    if (this.isMobileDisplay) {
      document
        .querySelector(".prefilled-results-wrap")
        .removeEventListener("scroll", this.onScroll);
      document
        .querySelector(".prefilled-modal")
        .removeEventListener("touchstart", this.handleTouchStart);
      document
        .querySelector(".prefilled-modal")
        .removeEventListener("touchmove", this.handleTouchMove);
      document
        .querySelector(".prefilled-modal")
        .removeEventListener("touchend", this.handleTouchEnd);

      document
        .querySelector(".prefilled-results")
        .removeEventListener("touchstart", this.handleTouchEndPrefillResult);
      document
        .querySelector(".prefilled-results")
        .removeEventListener("touchend", this.handleTouchEndPrefillResult);
    }
  },
};
</script>

<style lang="scss" scoped>
.editable-box {
  height: 79%;
}

.prefilled__btn svg {
  fill: #fff !important;
  margin: 0 4px 0 0 !important;
  width: 12px !important;
  height: 12px !important;
}
.prefilled__btn {
  font-size: 14px;
  display: block;
  align-items: center;
  padding: 3px 10px !important;
  background: #4874e6;
  text-align: center;
  margin: 0 auto;
  border-radius: 20px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: bold;
}

.prefilled-wrap {
  @media (min-width: 576px) {
    top: 10px !important;
    margin: 13px;
  }
}

.prefilled {
  &__btn {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    color: $body-color;

    @media (min-width: map_get($grid-breakpoints, sm)) {
      padding: 10px 16px;
    }

    svg {
      margin-left: 10px;
      width: 20px;
      height: 20px;
      fill: $primary;
    }

    &:hover {
      color: $primary;
    }

    &--active {
      svg {
        fill: #c5d2f5;
      }
    }
  }
}
header.creation-header {
  z-index: 1 !important;
}

.desktop button.btn.save_btn.btn--primary {
  background: #4874e6;
  color: #fff;
  padding: 10px;
  width: 150px;
  border-radius: 5px;
  float: right;
  margin: 20px 0;
  font-size: 18px;
}

.desktop button.btn.cancel_btn.btn--secondary {
  background: #f5f5f5;
  color: #000;
  padding: 10px;
  width: 150px;
  border-radius: 5px;
  float: left;
  margin: 20px 0;
  border: 1px solid;
  font-size: 18px;
}

.desktop.overlayer {
  position: fixed;
  background: black;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.5;
}
.desktop .prefilled-modal {
  display: block;
  z-index: 11;
  position: fixed;
  width: 100%;
  background: #4874e6;
  padding: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  border-radius: 0;
  height: 100%;
}
.desktop .resultboard {
  display: inline-block;
  width: 60%;
  float: left;
  background: #fff;
  height: 100%;
  padding: 20px;
  border-radius: 0;
}
.desktop .selectionboard {
  display: inline-block;
  width: 40%;
  padding: 20px;
  border-radius: 0 15px 15px 0;
}
.desktop .board_title {
  text-transform: uppercase;
  font-size: 15px;
}
.desktop .sub_title {
  font-size: 13px;
}
.desktop .selecteditems {
  height: 79%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin: 15px 0;
  padding: 20px;
  overflow-y: hidden;
}

.desktop .prefilled-results-wrap {
  padding-right: 5px;
  margin-top: 25px;
  height: 85vh;

  &::-webkit-scrollbar {
    background-color: #4c7aef;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #4c7aef;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
}

.desktop span#searchicon {
  width: 18px;
  position: absolute;
  fill: #9f9f9f;
  margin-left: 15px;
}

.prefilled-modal .prefilled-results-loading {
  background-color: rgba(#4874e6, 0.8);
  display: block;
  position: absolute;
  height: 1000vw;
  width: 100%;
  backdrop-filter: blur(2px);
}

#loader-btn {
  border-top-color: #fff !important;
  border-right-color: #fff !important;
  :before {
    border-top-color: #fff !important;
    border-right-color: #fff !important;
  }

  :after {
    border-top-color: #fff !important;
    border-right-color: #fff !important;
  }
}

.prefilled-results {
  &:hover {
    fill: #fff;
    .prefilled-results-item__icon {
      fill: #fff;
    }
  }
}

svg.prefilled-results-item__icon {
  fill: #fff;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 5px;
  width: 20px;
  height: 20px;
}

.prefilled-results-item {
  background: #4874e6 !important;
  color: #fff;
}
.prefilled-results-item:hover {
  background-color: #4369cd;
}

.prefilled-search__field {
  padding: 10px 0 10px 16px;
  flex: 1;
  border: none;
  border-radius: 40px;
  font-size: 13px;
}
.prefilled-search {
  border: none !important;
  margin-bottom: 10px;
}
.prefilled-search__btn {
  display: none;
}
.prefilled-search__btn svg {
  fill: #fff !important;
  width: 25px !important;
  height: 25px !important;
  @media (max-width: 500px) {
    width: 40px !important;
    height: 40px !important;
  }
}
.prefilled-search__btn:hover svg {
  fill: #ecf1ff;
}

.prefilled-close-is-ios {
  right: 24px !important;
}
.prefilled-close {
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 0;
  top: -54px;
  margin-right: -25px;
}
.prefilled-close__btn {
  display: flex;
  background: #4874e6;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-size: 12px;
  font-weight: bolder;
  color: white;
  @media (min-width: 993px) {
    display: none;
  }
}
.prefilled-close__btn:hover {
  color: #ecf1ff;
}

.no-prefilled p {
  color: #fff;
}

.prefilled-modal {
  background-color: #4874e6;
  padding: 24px 24px 0 24px;
  box-shadow: 0px -10px 24px rgba(0, 0, 0, 0.1);
  z-index: 10;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  transform: translateY(110%);
  transition: all 0.3s ease;

  @media (max-width: 991px) {
    max-height: calc(100vh - 175px);
  }

  @media (min-width: map_get($grid-breakpoints, lg)) {
    display: block;
    position: absolute;
    bottom: auto;
    top: 100%;
    height: auto;
    width: 600px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.15);
    padding: 24px;
    border-radius: 8px;
    transform: none;
  }

  @media (min-width: map_get($grid-breakpoints, xl)) {
    right: 15px;
    top: 100%;
    bottom: auto;
    left: auto;
    width: 600px;
  }

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    &__btn {
      height: 24px;
      width: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 0;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.prefilled-search {
  width: 100%;
  border-bottom: 1px solid #f7f5f5;
  display: flex;
  align-items: center;

  &__field {
    padding: 16px 0 16px 16px;
    @media (max-width: 992px) {
      margin-top: -30px;
      padding: 5px 0 5px 16px;
    }
    flex: 1;
    border: none;
  }

  &__btn {
    @media (max-width: 992px) {
      margin-top: -30px;
    }
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: $light-gray;
      width: 14px;
      height: 14px;
    }

    &:hover {
      svg {
        fill: $primary;
      }
    }
  }
}

.prefilled-results-wrap {
  flex: 1;
  overflow-y: auto;
  position: relative;
  padding-right: 5px;
  overscroll-behavior: contain;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    height: 240px;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #4c7aef;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #4c7aef;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 8px;
    outline: none;
  }
}

.prefilled-results {
  padding-top: 16px;

  &-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f4f7fc;
    border-radius: 4px;
    margin-bottom: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: darken(#f4f7fc, 3%);

      .prefilled-results-item__icon {
        fill: #fff;
      }
    }

    &__icon {
      width: 10px;
      height: 10px;
      margin-right: 10px;
    }

    &__text {
      font-size: 12px;
      line-height: 130%;
      flex: 1;
    }
  }
}

.no-prefilled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90%;
  color: $light-gray;
}

.prefilled-modal--open {
  transform: translateY(0);
}

.editor-wrap {
  position: relative;
  height: 79%;
  margin-top: 25px;

  ::v-deep .quill-editor {
    height: 95% !important;

    .ql-container {
      height: 90% !important;

      .ql-editor {
        height: 100% !important;
        max-height: 100% !important;
      }
    }
  }
}
</style>
