<template>
  <div class="change-links">
    <h2 class="change-links__title">Replace Text</h2>

    <AppField
      class="change-links__field"
      label="Type new text"
      required
      :isInvalid="isInvalidField"
      initFocus
      @input="setField"
    >
      <template v-slot:error>
        <p>This field is required</p>
      </template>
    </AppField>

    <div class="change-links-btns">
      <AppButton
        title="Save"
        class="change-links-btns__item"
        className="primary"
        @click.prevent="submitReplacementText"
      />

      <button
        class="change-links-btns__item change-links-btns__item--cancel"
        @click.prevent="cancel"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeKeysIntoEditorModal",

  computed: {
    isInvalidField() {
      return this.enableValidation && !this.replacedString;
    },
  },

  data() {
    return {
      replacedString: "",
      enableValidation: false,
    };
  },

  methods: {
    setField(newValue) {
      this.replacedString = newValue;
    },

    cancel() {
      this.$emit("cancel");
    },

    submitReplacementText() {
      this.enableValidation = true;

      if (this.isInvalidField) return;

      this.$emit("input", this.replacedString);
    },
  },
};
</script>

<style lang="scss" scoped>
.change-links {
  &__title {
    margin-bottom: 25px;
    font-weight: 600;
    font-size: 18px;
  }
}

.change-links-btns {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 24px;

  &__item {
    @media (max-width: map_get($grid-breakpoints, md)) {
      width: 50%;
      min-width: initial;
    }

    &--cancel {
      padding: 0 32px;
    }
  }
}
</style>
