<template>
  <div
    ref="editor-wrap"
    class="editor-wrap"
    :class="{ 'editor-wrap--error': isInvalid }"
  >
    <label class="editor-label d-none d-md-block"
      >{{ label }}
      <sup v-if="required">*</sup>
    </label>

    <PreFilledModal
      v-if="enablePrefilled"
      class="prefilled-wrap"
      v-on="$listeners"
      @select="addPrefilled"
      @cancel="cancelChanges"
      :preFillDefaultValue="preFillDefaultValue"
      :preFillPlaceHolder="preFillPlaceHolder"
      :contentEditor="contentEditor"
      @openPrefilled="openPrefilled"
      @closePrefilled="closePrefilled"
      @updatePrefilled="updatePrefilled"
      :isEditing="isEditing"
      :label="label"
      :isPrefillEditable="isPrefillEditable"
    />

    <quill-editor
      ref="editor"
      v-model="contentEditor"
      :options="editorOptions"
      @ready="addListenerReplaceableLinks"
      @change="onEditorChange"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />

    <portal
      to="replacePrefilledModal"
      :key="`replace-modal-${isOpenReplaceModal}`"
    >
      <AppModal
        :isOpenModal="isOpenReplaceModal"
        width="460px"
        @close="closeChangeKeyModal"
      >
        <ChangeKeysIntoEditorModal
          @cancel="closeChangeKeyModal"
          @input="replaceLink"
        />
      </AppModal>
    </portal>

    <transition name="fade">
      <div v-if="isInvalid" class="error">
        <slot name="error" />
      </div>
    </transition>
  </div>
</template>

<script>
import $ from "jquery";
import { quillEditor } from "vue-quill-editor";
import PreFilledModal from "@/components/ui/PreFIlledModal";
import ChangeKeysIntoEditorModal from "@/components/ChangeKeysIntoEditorModal";
import AppToast from "@/helpers/toast-methods";

export default {
  name: "AppTextEditor",
  components: { quillEditor, PreFilledModal, ChangeKeysIntoEditorModal },

  props: {
    enablePrefilled: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
    },

    label: {
      type: String,
    },

    isInvalid: {
      type: Boolean,
      default: false,
    },

    isCoverLetter: {
      type: Boolean,
      default: false,
    },

    preFillDefaultValue: {
      type: String,
    },

    preFillPlaceHolder: {
      type: String,
    },

    isEditing: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    isPrefillEditable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      contentEditor: "",
      replaceableString: "",
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
      },
      isOpenReplaceModal: false,
      localCursorPosition: null,
    };
  },

  methods: {
    updatePrefilled(content) {
      this.contentEditor = content;
      this.$emit("input", this.contentEditor);
    },

    /**
     * Fnc for add string to text editor with unic id for all replaceable parts
     * @param {String} preFilled
     */
    addPrefilled(preFilled) {
      const countVariables = preFilled.match(/<span[^>]*>/g);
      if (countVariables && countVariables.length) {
        // remove all anchor tags attribute (href=uuid) in this.contentEditor (selected items)  to match with preFilled (job description)  with no uuid or anchor
        this.contentEditor = this.contentEditor.replace(/<a[^>]*>/g, "<a>");
        // replace span to anchor (end tag)
        let preFilledWithoutAttribute = preFilled.replace(/<\/span>/g, "</a>");
        // replace all span to anchor (start tag)
        let span = preFilled.match(/<span[^>]*>/g);
        if (span && span.length) {
          span.forEach(() => {
            preFilledWithoutAttribute = preFilledWithoutAttribute.replace(
              /<span[^>]*>/,
              "<a>"
            );
          });
        }
        // if two anchor tags is side by side the space between them in this.contentEditor (selected items) are removed/missing (unknown reason)
        // this code added the space between them to find/match the preFilledWithoutAttribute (job description) in this.contentEditor (selected items)
        const noSpaceAnchorTags = this.contentEditor.match(/<\/a><a>/g);
        if (noSpaceAnchorTags && noSpaceAnchorTags.length) {
          noSpaceAnchorTags.forEach(() => {
            this.contentEditor = this.contentEditor.replace(
              "</a><a>",
              "</a> <a>"
            );
          });
        }

        //  finding the (job description) preFilledWithoutAttribute in (selected items) this.contentEditor
        if (
          this.contentEditor.includes(`<li>${preFilledWithoutAttribute}</li>`)
        ) {
          // remove
          this.contentEditor = this.contentEditor.replace(
            `<li>${preFilledWithoutAttribute}</li>`,
            ""
          );
        } else if (this.contentEditor.includes(preFilledWithoutAttribute)) {
          // remove
          this.contentEditor = this.contentEditor.replace(
            preFilledWithoutAttribute,
            ""
          );
        } else {
          // add
          this.contentEditor = this.isCoverLetter
            ? preFilledWithoutAttribute
            : this.contentEditor +
              `<ul><li>${preFilledWithoutAttribute}</li></ul>`;
        }
        // add uuid to all replaceable parts
        const anchors = this.contentEditor.match(/<a[^>]*>/g);
        if (anchors && anchors.length) {
          anchors.forEach((anchor) => {
            this.contentEditor = this.contentEditor.replace(
              anchor,
              `<a href="${this.$uuid.v4()}">`
            );
          });
        }

        return;
      }
      if (this.isCoverLetter) return;
      if (this.contentEditor.includes(`<li>${preFilled}</li>`)) {
        this.contentEditor = this.contentEditor.replace(
          `<li>${preFilled}</li>`,
          ""
        );
      } else if (this.contentEditor.includes(preFilled)) {
        this.contentEditor = this.contentEditor.replace(preFilled, "");
      } else {
        this.contentEditor =
          this.contentEditor + `<ul><li>${preFilled}</li></ul>`;
      }
    },

    cancelChanges(preFilledBackup) {
      this.contentEditor = preFilledBackup;
    },

    addListenerReplaceableLinks() {
      this.$refs["editor-wrap"]
        .querySelector(".ql-editor")
        .addEventListener("click", this.replaceableLinksHandler);
    },

    replaceableLinksHandler(e) {
      if (!e.target.closest("a")) return;

      e.preventDefault();
      e.stopPropagation();

      const cursorPosition = this.$refs.editor.quill.getSelection();

      this.localCursorPosition = cursorPosition.index;

      this.$refs.editor.quill.blur();

      // Text editor add "draggable" to every links
      e.target.closest("a").removeAttribute("draggable");

      this.openChangeKeyModal();

      this.replaceableString = e.target.closest("a");
    },

    replaceLink(newString) {
      this.closeChangeKeyModal();

      if (!this.replaceableString) {
        AppToast.toastError("No replacement text!");

        return;
      }

      const stringHref = this.replaceableString.getAttribute("href");

      const replacementString = `<a href="${stringHref}"`;

      const targetString = new RegExp(`${replacementString}[\\s\\S]*?<\\/a>`);

      this.contentEditor = this.contentEditor.replace(targetString, newString);

      this.setEditorCursorAtTheEnd();
    },

    onEditorChange() {
      this.$emit("input", this.contentEditor);
    },

    openChangeKeyModal() {
      this.isOpenReplaceModal = true;
    },

    closeChangeKeyModal() {
      this.isOpenReplaceModal = false;
    },

    setEditorCursorAtTheEnd() {
      setTimeout(() => {
        this.$refs.editor.quill.setSelection(
          this.localCursorPosition || this.contentEditor.length,
          0
        );
      }, 0);
    },

    openPrefilled() {
      $(".creation-desktop-form-footer").css("z-index", 0);
      this.$emit("openPrefilled");
    },

    closePrefilled() {
      $(".creation-desktop-form-footer").css("z-index", 999);
      this.$emit("closePrefilled");
      this.$refs.editor.quill.enable();
    },
  },

  created() {
    if (this.value) {
      this.contentEditor = this.value;

      if (this.isCoverLetter) {
        this.addPrefilled(this.value);
      }
    }
  },

  beforeDestroy() {
    this.$refs["editor-wrap"]
      .querySelector(".ql-editor")
      .removeEventListener("click", this.replaceableLinksHandler);
  },
};
</script>

<style lang="scss" scoped>
.editor-wrap {
  position: relative;

  .editor-label {
    position: absolute;
    left: 16px;
    top: 0;
    transform: translateY(-50%);
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    background-color: #fff;
    color: $light-gray;
    padding: 3px;
  }

  ::v-deep .quill-editor {
    border: 1px solid #d6d8dc;
    border-radius: 8px;

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border: none;
    }

    .ql-toolbar {
      padding: 50px 16px 15px;
      @media (min-width: map_get($grid-breakpoints, lg)) {
        padding: 27px 16px;
      }
    }

    .ql-container {
      padding: 16px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 16px;
        right: 16px;
        top: 0;
        height: 1px;
        background-color: #f6f5f5;
      }

      .ql-editor {
        padding: 0 5px 0 0;
        min-height: 115px;
        max-height: 215px;
        font-size: 14px;

        @media (min-width: map_get($grid-breakpoints, md)) {
          max-height: 135px;
        }

        @media (min-width: map_get($grid-breakpoints, xl)) {
          max-height: 170px;
        }

        &::-webkit-scrollbar {
          width: 2px;
          background-color: #f1f1f1;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $primary;
          border-radius: 8px;
          outline: none;
        }
      }
    }
  }
}

.editor-wrap--error {
  ::v-deep .quill-editor {
    border-color: $red;
  }

  .editor-label {
    color: $red;
  }
}

.prefilled-wrap {
  position: absolute;
  right: 0;
  top: 7px;
  left: auto;
  @media (max-width: 414px) {
    left: 10px;
    top: 10px;
    right: auto;
  }
}

::v-deep .ql-snow .ql-tooltip {
  display: none !important;
}

::v-deep .modal {
  @media (max-width: map_get($grid-breakpoints, md)) {
    padding: 20px 16px;
  }
}

.error {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 13px;
  font-weight: 500;
  color: $red;
}
</style>
